import request from '@/utils/request'
import qs from 'qs'
// 作者订单
export function authororderlist (id,page,limit,state) {
  let data = {
    id,page,limit,state
  }
  data = qs.stringify(data)
  return request({
    url: '/author/AuthorTodoorder',
    method: 'post',
    data
  })
}


