<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick">



      <el-tab-pane name="first">
        <span slot="label">待缴费（{{ total }}）</span>
        <div v-if="orderlistlength != 0">
          <div style="padding: 10px">
            <el-table
              ref="multipleTable"
              :data="orderlist"
              tooltip-effect="dark"
              style="width: 100%"
            >
              <el-table-column label="文章标题" sortable show-overflow-tooltip>
                <template slot-scope="scope">
                  <div class="ordertable">
                    <div class="ygdleft">
                      <p class="biaoti">{{ scope.row.title }}</p>
                      <p>
                        <span>{{ scope.row.name }}</span
                        >&nbsp;<span>{{ scope.row.editor }}</span>
                      </p>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                show-overflow-tooltip
                label="按投稿时间排序"
                prop="Contribution_time"
                align="center"
                sortable
              >
                <template slot-scope="scope">
                  <div class="ordertable">
                    <div class="ygdright">
                      <div style="height: 20px"></div>
                      <span
                        ><i class="el-icon-s-promotion"></i>投稿时间：{{
                          scope.row.Contribution_time
                        }}</span
                      >
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                show-overflow-tooltip
                label="按属性排序"
                prop="attribute"
                sortable
              >
                <template slot-scope="scope">
                  <div class="ordertable">
                    <div class="ygdright">
                      <div style="height: 20px"></div>
                      <span
                        @click="showDetail(scope.row.id)"
                        style="cursor: pointer"
                        >查看详情</span
                      >
                      <div class="urgent" v-if="scope.row.attribute == 1">
                        加急
                      </div>
                      <div class="nourgent" v-if="scope.row.attribute == 0">
                        普通
                      </div>
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <!-- <div>
						<div class="orderlist" v-for="(item,key) in orderlist" @click="showDetail(item.id)">
							<div class="ygdleft">
								<p class="biaoti">{{item.title}}</p>
								<p><span>{{item.name}}</span><span>{{item.editor}}</span></p>
							</div>
							<div class="ygdright">
								<div class="urgent" v-show="item.attribute==1">
									加急
								</div>
								<div style="height: 20px;"></div>
								<span> <i class="el-icon-s-promotion"></i>投稿时间：{{item.Contribution_time}}</span>
							</div>
						</div>
					</div -->
            <div
              class="ygdPage"
              style="
                display: flex;
                justify-content: center;
                width: 100%;
                margin-top: 20px;
              "
            >
              <el-pagination
                class="fy"
                small
                @size-change="handleSizeChange"
                :page-size="pagesize"
                :page-sizes="[10, 20, 30]"
                layout="total, sizes, prev, pager, next, jumper"
                @current-change="current_change"
                :total="total"
                background
              >
              </el-pagination>
            </div>
          </div>
        </div>
        <div v-else>
          <div
            style="
              text-align: center;
              color: #999999;
              padding: 20px;
              width: 100%;
            "
          >
            暂无数据
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane name="second">
        <span slot="label">被驳回（{{ total2 }}）</span>
        <div v-if="orderlistlength1 != 0">
          <div style="padding: 10px">
            <el-table
              ref="multipleTable"
              :data="orderlist1"
              tooltip-effect="dark"
              style="width: 100%"
            >
              <el-table-column label="文章标题" sortable show-overflow-tooltip>
                <template slot-scope="scope">
                  <div class="ordertable">
                    <div class="ygdleft">
                      <p class="biaoti">{{ scope.row.title }}</p>
                      <p>
                        <span>{{ scope.row.name }}</span
                        >&nbsp;<span>{{ scope.row.editor }}</span>
                      </p>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                show-overflow-tooltip
                label="按投稿时间排序"
                prop="Contribution_time"
                align="center"
                sortable
              >
                <template slot-scope="scope">
                  <div class="ordertable">
                    <div class="ygdright">
                      <div style="height: 20px"></div>
                      <span
                        ><i class="el-icon-s-promotion"></i>投稿时间：{{
                          scope.row.Contribution_time
                        }}</span
                      >
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                show-overflow-tooltip
                label="按属性排序"
                prop="attribute"
                sortable
              >
                <template slot-scope="scope">
                  <div class="ordertable">
                    <div class="ygdright">
                      <div style="height: 20px"></div>
                      <span
                        @click="showbhDetail(scope.row.id, scope.row.state,scope.row.isUseFws)"
                        style="cursor: pointer"
                        >查看详情</span
                      >
                      <div class="urgent" v-if="scope.row.attribute == 1">
                        加急
                      </div>
                      <div class="nourgent" v-if="scope.row.attribute == 0">
                        普通
                      </div>
                      <div
                        class="who"
                        v-show="scope.row.state == 81 || scope.row.state == 82"
                      >
                        服务商
                      </div>
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div
            class="ygdPage"
            style="
              display: flex;
              justify-content: center;
              width: 100%;
              margin-top: 20px;
            "
          >
            <el-pagination
              class="fy"
              small
              @size-change="handleSizeChange2"
              :page-size="pagesize2"
              :page-sizes="[10, 20, 30]"
              layout="total, sizes, prev, pager, next, jumper"
              @current-change="current_change2"
              :total="total2"
              background
            >
            </el-pagination>
          </div>
        </div>
        <div v-else>
          <div
            style="
              text-align: center;
              color: #999999;
              padding: 20px;
              width: 100%;
            "
          >
            暂无数据
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane name="third">
        <span slot="label">待确认（{{ total3 }}）</span>
        <div v-if="orderlistlength2 != 0">
          <div style="padding: 10px">
            <el-table
              ref="multipleTable"
              :data="orderlist2"
              tooltip-effect="dark"
              style="width: 100%"
            >
              <el-table-column label="文章标题" sortable show-overflow-tooltip>
                <template slot-scope="scope">
                  <div class="ordertable">
                    <div class="ygdleft">
                      <p class="biaoti">{{ scope.row.title }}</p>
                      <p>
                        <span>{{ scope.row.name }}</span
                        >&nbsp;<span>{{ scope.row.editor }}</span>
                      </p>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                show-overflow-tooltip
                label="按投稿时间排序"
                prop="Contribution_time"
                align="center"
                sortable
              >
                <template slot-scope="scope">
                  <div class="ordertable">
                    <div class="ygdright">
                      <div style="height: 20px"></div>
                      <span
                        ><i class="el-icon-s-promotion"></i>投稿时间：{{
                          scope.row.Contribution_time
                        }}</span
                      >
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                show-overflow-tooltip
                label="按属性排序"
                prop="attribute"
                sortable
              >
                <template slot-scope="scope">
                  <div class="ordertable">
                    <div class="ygdright">
                      <div style="height: 20px"></div>
                      <span
                        @click="showDetail(scope.row.id)"
                        style="cursor: pointer"
                        >查看详情</span
                      >
                      <div class="urgent" v-if="scope.row.attribute == 1">
                        加急
                      </div>
                      <div class="nourgent" v-if="scope.row.attribute == 0">
                        普通
                      </div>
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <div
              class="ygdPage"
              style="
                display: flex;
                justify-content: center;
                width: 100%;
                margin-top: 20px;
              "
            >
              <el-pagination
                class="fy"
                small
                @size-change="handleSizeChange3"
                :page-size="pagesize3"
                :page-sizes="[10, 20, 30]"
                layout="total, sizes, prev, pager, next, jumper"
                @current-change="current_change3"
                :total="total3"
                background
              >
              </el-pagination>
            </div>
          </div>
        </div>
        <div v-else>
          <div
            style="
              text-align: center;
              color: #999999;
              padding: 20px;
              width: 100%;
            "
          >
            暂无数据
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane name="fifth">
        <span slot="label">已确认（{{ total5 }}）</span>
        <div v-if="orderlistlength4 != 0">
          <div style="padding: 10px">
            <el-table
                    ref="multipleTable"
                    :data="orderlist4"
                    tooltip-effect="dark"
                    style="width: 100%"
            >
              <el-table-column label="文章标题" sortable show-overflow-tooltip>
                <template slot-scope="scope">
                  <div class="ordertable">
                    <div class="ygdleft">
                      <p class="biaoti">{{ scope.row.title }}</p>
                      <p>
                        <span>{{ scope.row.name }}</span
                        >&nbsp;<span>{{ scope.row.editor }}</span>
                      </p>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                      show-overflow-tooltip
                      label="按投稿时间排序"
                      prop="Contribution_time"
                      align="center"
                      sortable
              >
                <template slot-scope="scope">
                  <div class="ordertable">
                    <div class="ygdright">
                      <div style="height: 20px"></div>
                      <span
                      ><i class="el-icon-s-promotion"></i>投稿时间：{{
                          scope.row.Contribution_time
                        }}</span
                      >
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                      show-overflow-tooltip
                      label="按属性排序"
                      prop="attribute"
                      sortable
              >
                <template slot-scope="scope">
                  <div class="ordertable">
                    <div class="ygdright">
                      <div style="height: 20px"></div>
                      <span
                              @click="showDetail(scope.row.id)"
                              style="cursor: pointer"
                      >查看详情</span
                      >
                      <div class="urgent" v-if="scope.row.attribute == 1">
                        加急
                      </div>
                      <div class="nourgent" v-if="scope.row.attribute == 0">
                        普通
                      </div>
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <div
                    class="ygdPage"
                    style="
                display: flex;
                justify-content: center;
                width: 100%;
                margin-top: 20px;
              "
            >
              <el-pagination
                      class="fy"
                      small
                      @size-change="handleSizeChange5"
                      :page-size="pagesize4"
                      :page-sizes="[10, 20, 30]"
                      layout="total, sizes, prev, pager, next, jumper"
                      @current-change="current_change5"
                      :total="total5"
                      background
              >
              </el-pagination>
            </div>
          </div>
        </div>
        <div v-else>
          <div
                  style="
              text-align: center;
              color: #999999;
              padding: 20px;
              width: 100%;
            "
          >
            暂无数据
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane name="fourth">
        <span slot="label">历史订单（{{ total4 }}）</span>
        <div v-if="orderlistlength3 != 0">
          <div style="padding: 10px">
            <el-table
                    ref="multipleTable"
                    :data="orderlist3"
                    tooltip-effect="dark"
                    style="width: 100%"
            >
              <el-table-column label="文章标题" sortable show-overflow-tooltip>
                <template slot-scope="scope">
                  <div class="ordertable">
                    <div class="ygdleft">
                      <p class="biaoti">{{ scope.row.title }}</p>
                      <p>
                        <span>{{ scope.row.name }}</span
                        >&nbsp;<span>{{ scope.row.editor }}</span>
                      </p>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                      show-overflow-tooltip
                      label="按投稿时间排序"
                      prop="Contribution_time"
                      align="center"
                      sortable
              >
                <template slot-scope="scope">
                  <div class="ordertable">
                    <div class="ygdright">
                      <div style="height: 20px"></div>
                      <span
                      ><i class="el-icon-s-promotion"></i>投稿时间：{{
                          scope.row.Contribution_time
                        }}</span
                      >
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                      show-overflow-tooltip
                      label="按属性排序"
                      prop="attribute"
                      sortable
              >
                <template slot-scope="scope">
                  <div class="ordertable">
                    <div class="ygdright">
                      <div style="height: 20px"></div>
                      <span
                              @click="showDetail(scope.row.id)"
                              style="cursor: pointer"
                      >查看详情</span
                      >
                      <div class="urgent" v-if="scope.row.attribute == 1">
                        加急
                      </div>
                      <div class="nourgent" v-if="scope.row.attribute == 0">
                        普通
                      </div>
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <div
                    class="ygdPage"
                    style="
                display: flex;
                justify-content: center;
                width: 100%;
                margin-top: 20px;
              "
            >
              <el-pagination
                      class="fy"
                      small
                      @size-change="handleSizeChange4"
                      :page-size="pagesize4"
                      :page-sizes="[10, 20, 30]"
                      layout="total, sizes, prev, pager, next, jumper"
                      @current-change="current_change4"
                      :total="total4"
                      background
              >
              </el-pagination>
            </div>
          </div>
        </div>
        <div v-else>
          <div
                  style="
              text-align: center;
              color: #999999;
              padding: 20px;
              width: 100%;
            "
          >
            暂无数据
          </div>
        </div>
      </el-tab-pane>



      <el-tab-pane name="sixth">
        <span slot="label">审核中（{{ total6 }}）</span>
        <div v-if="orderlistlength6 != 0">
          <div style="padding: 10px">
            <el-table
                    ref="multipleTable"
                    :data="orderlist6"
                    tooltip-effect="dark"
                    style="width: 100%"
            >
              <el-table-column label="文章标题" sortable show-overflow-tooltip>
                <template slot-scope="scope">
                  <div class="ordertable">
                    <div class="ygdleft">
                      <p class="biaoti">{{ scope.row.title }}</p>
                      <p>
                        <span>{{ scope.row.name }}</span
                        >&nbsp;<span>{{ scope.row.editor }}</span>
                      </p>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                      show-overflow-tooltip
                      label="按投稿时间排序"
                      prop="Contribution_time"
                      align="center"
                      sortable
              >
                <template slot-scope="scope">
                  <div class="ordertable">
                    <div class="ygdright">
                      <div style="height: 20px"></div>
                      <span
                      ><i class="el-icon-s-promotion"></i>投稿时间：{{
                          scope.row.Contribution_time
                        }}</span
                      >
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                      show-overflow-tooltip
                      label="按属性排序"
                      prop="attribute"
                      sortable
              >
                <template slot-scope="scope">
                  <div class="ordertable">
                    <div class="ygdright">
                      <div style="height: 20px"></div>
                      <span
                              @click="showDetail(scope.row.id)"
                              style="cursor: pointer"
                      >查看详情</span
                      >
                      <div class="urgent" v-if="scope.row.attribute == 1">
                        加急
                      </div>
                      <div class="nourgent" v-if="scope.row.attribute == 0">
                        普通
                      </div>
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <!-- <div>
						<div class="orderlist" v-for="(item,key) in orderlist" @click="showDetail(item.id)">
							<div class="ygdleft">
								<p class="biaoti">{{item.title}}</p>
								<p><span>{{item.name}}</span><span>{{item.editor}}</span></p>
							</div>
							<div class="ygdright">
								<div class="urgent" v-show="item.attribute==1">
									加急
								</div>
								<div style="height: 20px;"></div>
								<span> <i class="el-icon-s-promotion"></i>投稿时间：{{item.Contribution_time}}</span>
							</div>
						</div>
					</div -->
            <div
                    class="ygdPage"
                    style="
                display: flex;
                justify-content: center;
                width: 100%;
                margin-top: 20px;
              "
            >
              <el-pagination
                      class="fy"
                      small
                      @size-change="handleSizeChange6"
                      :page-size="pagesize6"
                      :page-sizes="[10, 20, 30]"
                      layout="total, sizes, prev, pager, next, jumper"
                      @current-change="current_change6"
                      :total="total"
                      background
              >
              </el-pagination>
            </div>
          </div>
        </div>
        <div v-else>
          <div
                  style="
              text-align: center;
              color: #999999;
              padding: 20px;
              width: 100%;
            "
          >
            暂无数据
          </div>
        </div>
      </el-tab-pane>




    </el-tabs>
  </div>
</template>

<script>
import { authororderlist } from "../../../API/ZZD/order.js"
export default {
  components: {

  },
  data () {
    return {
      activeName: 'first',
      orderlist: [],
      orderlist1: [],
      orderlist2: [],
      orderlist3: [],
      orderlist4: [],
      orderlist6: [],
      total: 0, //默认数据总数
      pagesize: 10, //每页的数据条数
      currentPage: 1, //默认开始页面
      total2: 0, //默认数据总数
      pagesize2: 10, //每页的数据条数
      currentPage2: 1, //默认开始页面
      total3: 0, //默认数据总数
      pagesize3: 10, //每页的数据条数
      currentPage3: 1, //默认开始页面
      total4: 0,
      pagesize4: 10, //每页的数据条数
      currentPage4: 1, //默认开始页面
      total5: 0,
      pagesize5: 10, //每页的数据条数
      currentPage5: 1, //默认开始页面

      total6: 0,
      pagesize6: 10, //每页的数据条数
      currentPage6: 1, //默认开始页面
      now: "",
      orderlistlength: 0,
      orderlistlength1: 0,
      orderlistlength2: 0,
      orderlistlength3: 0,
      orderlistlength4: 0,
      orderlistlength6: 0,

    }

  },
  mounted () {

    this.selecttab()
    this.payorder()
    this.qrorder()
    this.juorder()
    this.hisorder()
    this.fiforder()
    this.apply()

  },
  methods: {
    handleClick (tab, event) {
      console.log(tab.index);
      this.now = tab.index
    },
    // 分页
    current_change: function (currentPage) {
      this.currentPage = currentPage;
      this.payorder()
    },
    handleSizeChange (val) {
      console.log(val);
      this.pagesize = val;
      this.payorder()
    }, // 分页
    current_change2: function (currentPage) {
      this.currentPage2 = currentPage;
      this.juorder()
    },
    handleSizeChange2 (val) {
      console.log(val);
      this.pagesize2 = val;
      this.juorder()
    }, // 分页
    current_change3: function (currentPage) {
      this.currentPage3 = currentPage;
      this.qrorder()
    },
    handleSizeChange3 (val) {
      console.log(val);
      this.pagesize3 = val;
      this.qrorder()
    },
    // 分页
    current_change4: function (currentPage) {
      this.currentPage4 = currentPage;
      this.hisorder()
    },
    handleSizeChange4 (val) {
      console.log(val);
      this.pagesize4 = val;
      this.hisorder()
    },
    // 分页
    current_change5: function (currentPage) {
      this.currentPage5 = currentPage;
      this.fiforder()
    },
    handleSizeChange5 (val) {
      console.log(val);
      this.pagesize5 = val;
      this.fiforder()
    },

    current_change6: function (currentPage) {
      this.currentPage6 = currentPage;
      this.apply()
    },
    handleSizeChange6 (val) {
      console.log(val);
      this.pagesize6 = val;
      this.apply()
    },

    showDetail (wid, attr,isUseFws) {
		console.log(wid,attr,isUseFws,'shenqg--------------------------')
      this.$router.push({ path: "/Pending/todoorder/detail", query: { "id": 0, "now": this.now, 'wid': wid ,isUseFws:isUseFws} })
    }, showbhDetail (wid, attr,isUseFws) {
      if (attr == 54 || attr == 55) {//申请改变服务商的订单
        this.$router.push({ path: "/Pending/todoorder/changeDetail", query: { "id": 0, "now": this.now, 'wid': wid, } })
      } else if (attr == 44 || attr == 45) {//申请退款的订单
        this.$router.push({ path: "/Pending/todoorder/drawbackdetail", query: { "id": 0, "now": this.now, 'wid': wid } })
      } else {//被驳回未处理的订单包括服务商被驳回用attr控制
        this.$router.push({ path: "/Pending/todoorder/detail", query: { "id": 0, "now": this.now, 'wid': wid, attr: attr } })
      }
    }, selecttab () {
      if (this.$route.query.now) {
        console.log("zhixing ")
        this.now = this.$route.query.now
        if (this.now == 0) {
          this.activeName = "first"
        } else if (this.now == 1) {
          this.activeName = "second"
        } else if (this.now == 2) {
          this.activeName = "third"
        }else if (this.now == 3) {
          this.activeName = "fourth"
        }else if (this.now == 4) {
          this.activeName = "fifth"
        }else if(this.now == 5){
          this.activeName = "sixth"
        }
      }
    },
    payorder () {
      var id = localStorage.getItem('ZZId');
      authororderlist(id, this.currentPage, this.pagesize, 6).then(res => {
        console.log(res,'idididididiidididzuozhe')
        this.total = res.data.total
        this.orderlist = res.data.data.splice(1)
        this.orderlistlength = this.orderlist.length
      })
    },
    juorder () {
      var id = localStorage.getItem('ZZId');
      authororderlist(id, this.currentPage2, this.pagesize2, 7).then(res => {
        console.log(res)
        this.total2 = res.data.total
        this.orderlist1 = res.data.data.splice(1)
        this.orderlistlength1 = this.orderlist1.length
        console.log(this.orderlist1)
      })
    },
    qrorder () {
      var id = localStorage.getItem('ZZId');
      authororderlist(id, this.currentPage3, this.pagesize3, 5).then(res => {
        console.log(res)
        this.total3 = res.data.total
        this.orderlist2 = res.data.data.splice(1)
        this.orderlistlength2 = this.orderlist2.length
      })
    },
    hisorder () {
      var id = localStorage.getItem('ZZId');
      authororderlist(id, this.currentPage4, this.pagesize4, 4).then(res => {
        console.log(res)
        this.total4 = res.data.total
        this.orderlist3 = res.data.data
        this.orderlistlength3 = this.orderlist3.length
      })
    },

    apply () {
      var id = localStorage.getItem('ZZId');
      authororderlist(id, this.currentPage6, this.pagesize6, 0).then(res => {
        console.log(res)
        this.total6 = res.data.total
        this.orderlist6 = res.data.data
        this.orderlistlength6 = this.orderlist6.length
      })
    },
   fiforder () {
      var id = localStorage.getItem('ZZId');
      authororderlist(id, this.currentPage5, this.pagesize5, 47).then(res => {
        console.log(res)
        this.total5 = res.data.total
        this.orderlist4 = res.data.data.splice(1)
        this.orderlistlength4 = this.orderlist4.length
      })
    }
  }
}
</script>

<style>
.orderlist {
  position: relative;
  display: flex;
  justify-content: space-between;
  background: #f7f7f7;
  border-radius: 4px;
  padding: 10px;
  margin-top: 10px;
  cursor: pointer;
}

.biaoti {
  font-size: 14px;
}

.orderlist span {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  margin: 0 3px;
}

.urgent {
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  height: 24px;
  background: #ff1918;
  border-radius: 0px 4px 0px 4px;
  color: #fff;
  text-align: center;
  font-size: 12px;
  line-height: 24px;
}
.nourgent {
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  height: 24px;
  background: lightseagreen;
  border-radius: 0px 4px 0px 4px;
  color: #fff;
  text-align: center;
  font-size: 12px;
  line-height: 24px;
}
.who {
  position: absolute;
  right: 50px;
  top: 0;
  width: 40px;
  height: 24px;
  background: #2f7df7;
  border-radius: 0px 4px 0px 4px;
  color: #fff;
  text-align: center;
  font-size: 12px;
  line-height: 24px;
  padding: 0 5px;
}

.ygdPage {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ygdright {
  width: 100rpx;
}
</style>